import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useTracking } from "../utils/ga";

import HeadBlock from "./HeadBlock";
import Header from "./Header";
import FixedContents from "./FixedContents";

const metaData = {
    title : "利用規約 / RECOVERY STORY（リカバリーストーリー）",
    description : {
        name : "description",
        content : "RECOVERY STORY（リカバリーストーリー）は、ストレスがたまり気分が落ち込んだ時の乗り越え方や考え方の工夫、ストレス解消法の体験記の音声コンテンツ配信サービスです"
    },
    canonical : {
        rel: "canonical",
        href: "https://recoverystory.com/terms"
    },
    ogpDatas : [
        { property: "og:url", content: "https://recoverystory.com/terms" },
        { property: "og:title", content: "利用規約 / RECOVERY STORY（リカバリーストーリー）" },
        { property: "og:description", content: "RECOVERY STORY（リカバリーストーリー）は、ストレスがたまり気分が落ち込んだ時の乗り越え方や考え方の工夫、ストレス解消法の体験記の音声コンテンツ配信サービスです" }
    ],
    xDatas : [
        { name: "twitter:title", content: "利用規約 / RECOVERY STORY（リカバリーストーリー）" },
        { name: "twitter:description", content: "RECOVERY STORY（リカバリーストーリー）は、ストレスがたまり気分が落ち込んだ時の乗り越え方や考え方の工夫、ストレス解消法の体験記の音声コンテンツ配信サービスです" }
    ]
}

const Privacypolicy = () => {
    const { pathname } = useLocation();
    const { gtag, pageview } = useTracking();
    
    useEffect(() => {
        pageview(pathname);
    }, [pageview, pathname]);

    useEffect(() => {
        document.body.style.overflow = "auto";
        window.scrollTo(0, 0);
    });
    return (
        <HelmetProvider>
            <HeadBlock metaData={metaData} />
            <Header />
            <FixedContents />
            <div className="terms-wrap">
                <h2 className="terms-main-heading">RECOVERY STORY 利用規約</h2>
                <div className="terms-text-wrap">
                    <p className="terms-text">
                        本利用規約(以下｢本規約｣といいます。)は、NO NAME PROJECT株式会社(以下｢当社｣といいます。)が運営し提供するサービス｢RECOVERY STORY｣(以下｢本サービス｣といいます。)の提供条件及び当社とユーザー(第1条で定義します。)との間の権利義務関係を定めるものです。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。本サービスを利用される皆様は本規約に同意したものとみなされますので、本規約に同意しない場合は、本サービスを利用したり、本コンテンツ(第1条で定義します。)にアクセスしたりしないでください。
                    </p>
                    <div>
                        <h3 className="terms-heading">第1条.定義</h3>
                        <p className="terms-text">
                            本規約上で使用する用語は、各々以下に定める意味を有するものとします。
                        </p>
                        <ul className="terms-num-list">
                            <li className="terms-text">｢個別規約等｣とは、当社が当社ウェブサイトまたは当社アプリケーション上に掲載する、本規約以外の本サービスの利用に関する利用ルール等または個別の規約をいいます。</li>
                            <li className="terms-text"> ｢知的財産権｣とは、著作権、特許権、実用新案権、意匠権、その他の知的財産権(それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。)をいいます。</li>
                            <li className="terms-text">｢登録ユーザー｣とは、第3条第2項に基づいてアカウント登録がなされたユーザーをいいます。</li>
                            <li className="terms-text">｢アカウント情報｣とは、本サービスのアカウント登録にあたってユーザーが登録するまたは当社が発行するID、パスワードその他のユーザーに関する情報の一切をいいます。</li>
                            <li className="terms-text">｢反社会的勢力｣とは、(a)暴力団(暴力団員による不当な行為の防止等に関する法律(平成3年法律第77号。以下｢暴対法｣といいます。)第2条第2号に規定する暴力団をいいます。)、(b)暴力団員(暴対法第2条第6号に規定する暴力団員をいいます。)、(c)暴力団員でなくなった時から5年を経過しない者、(d)暴力団準構成員、(e)暴力団関係企業、(f)総会屋、(g)社会運動標ぼうゴロ、(h)政治活動標ぼうゴロ、(i)特殊知能暴力集団または(j)その他これらに準じる者をいいます。</li>
                            <li className="terms-text">｢法令等｣とは、法令、条例、これらに基づく関係官庁の通達、指導等をいいます。</li>
                            <li className="terms-text">｢本コンテンツ｣とは、当社による本サービスの提供に用いられる文章、画像、音声等のコンテンツをいいます。</li>
                            <li className="terms-text">｢本サービス利用契約｣とは、本規約を契約条件として当社とユーザー間で締結される本サービスの利用契約をいいます。</li>
                            <li className="terms-text">｢ユーザー｣とは、本サービスを本規約に従って利用する会員をいいます。</li>
                            <li className="terms-text">｢ユーザーコンテンツ｣とは、ユーザーが本サービスを利用して投稿または編集した文章、画像、音声等のコンテンツをいいます。</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第2条.本サービスの概要および本規約の適用範囲</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">本サービスは、ユーザーが当社ウェブサイトにアクセスしてまたは当社アプリケーションを用いて、本コンテンツを閲覧・視聴するとともに、自らが希望する場合はアカウント登録を行うことによってユーザーコンテンツを投稿・編集することが可能となるサービスです。</li>
                            <li className="terms-text">本規約は、本サービスの提供条件及び本サービスの利用に関する当社とユーザーとの間の権利義務関係を定めるものであり、当社とユーザーとの間の本サービスの利用に関する一切の関係に適用されます。</li>
                            <li className="terms-text">個別規約等は、本規約の一部を構成します。</li>
                            <li className="terms-text">本規約の内容と、前項に定める個別規約等との間で、相違または矛盾が存在する場合は、当該個別規約等に特段の定めがない限り、本規約の規定が優先します。</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第3条.利用資格</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">
                                本サービスは以下の条件をすべて満たす方に限り、ご利用いただくことができます。
                                <ul className="terms-abc-list">
                                    <li>自身でインターネットの利用環境、端末、ソフトウェアなどを用意することができること</li>
                                    <li>本規約に同意かつ遵守できること</li>
                                    <li>過去に本規約に違反したことのないこと</li>
                                </ul>
                            </li>
                            <li className="terms-text">
                                本コンテンツの閲覧・視聴を超えた本サービスの利用を希望する者は、所定の方法により、当社が定める一定の情報を当社に提供することにより、本サービスのアカウント登録を申請することができます。ただし、登録申請者が以下の各号のいずれかに該当する者と当社が判断した場合、当社はアカウント登録を拒否することがあります。この場合、当社はその理由について登録申請者に開示する義務を負いません。
                                <ul className="terms-abc-list">
                                    <li>当社に提供した情報に虚偽や誤記もしくは記載漏れがあった場合</li>
                                    <li>登録申請者が本サービスを受ける目的以外の目的でアカウント登録を申し込むものであると当社が判断した場合</li>
                                    <li>未成年、成年被後見人、被補佐人または被補助人のいずれかであり、法定代理人、後見人、補佐人または補助人の同意等を得ていなかった場合</li>
                                    <li>登録申請者が反社会的勢力等に該当する者またはこれに関与する者であるおそれがある場合</li>
                                    <li>登録申請者が過去に本規約を含む当社との契約に違反した者またはその関係者である場合</li>
                                    <li>第5条第1項各号に掲げる行為を行ったことがあるか、または行うおそれがある場合</li>
                                    <li>その他、当社においてアカウント登録が適切ではないと判断した場合</li>
                                </ul>
                            </li>
                            <li className="terms-text">登録ユーザーは、登録内容に変更がある場合には、その変更事項を速やかに当社の定める方法により通知するものとします。登録内容に変更があったにもかかわらず、変更がされていない場合は、当社は登録内容に変更がないものとして取り扱うことができます。本項の通知を怠ったことにより登録ユーザーが被った損害その他の不利益について、当社はその責任を負いません。</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第4条.アカウント情報の管理</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">登録ユーザーは、本サービスのアカウント情報（本サービスと連携する当社以外の者が運営するサービスのアカウント情報を含みます。）を、自己の責任において適切に管理および保管するものとします。ユーザーは、いかなる場合にも、アカウント情報の使用権限を第三者に譲渡または貸与することはできません。</li>
                            <li className="terms-text">登録ユーザーは、本サービスのアカウント登録にあたってアカウント情報として利用する、当社以外の者が運営するサービスの登録、利用について、当該外部サービスが規定する各規約の定めに従いユーザー自身の責任で行うものとします。</li>
                            <li className="terms-text">アカウント情報の管理不十分、第三者の使用等によって生じた損害または不利益に関する責任は登録ユーザーが負うものとします。</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第5条.禁止事項</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">
                            ユーザーは、本コンテンツの閲覧・視聴や本サービスの利用にあたり、以下の行為を自らまたは第三者を通じてしてはならず第三者が行う以下の行為に関与してはなりません。
                                <ul className="terms-abc-list">
                                    <li>法令等または公序良俗に違反する行為</li>
                                    <li>犯罪行為に関連する行為</li>
                                    <li>当社のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為</li>
                                    <li>本サービスの運営を妨害する行為、または妨害するおそれのある行為</li>
                                    <li>他者の個人情報等を収集または蓄積する行為</li>
                                    <li>他者に成りすます行為</li>
                                    <li>反社会的勢力に対して直接的または間接的に利益を供与する行為</li>
                                    <li>逆アセンブル、逆コンパイル、リバースエンジニアリングその他の当社または当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為</li>
                                    <li>他のユーザー、当社、または第三者の知的財産権、肖像権、プライバシー、名誉その他の権利または利益を侵害する行為</li>
                                    <li>他のユーザーまたは第三者を欺く虚偽の内容を記載する行為</li>
                                    <li>スパムとみなされる行為（機械により自動生成された文章の投稿や同一内容の文章を繰り返し投稿する行為など）</li>
                                    <li>過度に暴力的な表現、露骨な性的表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引または助長する表現、他人に不快感を与える表現等、不適切な内容を投稿する行為</li>
                                    <li>性行為やわいせつな行為を目的とする行為、面識のない異性との出会いや交際を目的とする行為</li>
                                    <li>他者に対する嫌がらせや誹謗中傷</li>
                                    <li>宗教活動または宗教団体への勧誘行為</li>
                                    <li>当社が定める一定のデータ容量以上のデータを本サービスを通じて送信する行為</li>
                                    <li>当社が定める方法以外の方法で、本サービスまたはコンテンツの利用権を、現金、財物その他の経済上の利益と交換する行為</li>
                                    <li>営業、宣伝、広告、勧誘、その他営利を目的とする行為</li>
                                    <li>その他、当社が不適切であると合理的に判断する行為</li>
                                </ul>
                            </li>
                            <li className="terms-text">ユーザーは、他のユーザー等による前項に定める行為を発見した場合、直ちに、別途定める当社の問い合わせ先に通知するものとします。なお、当該行為に対する調査、対応、公表等は、当社の裁量で行います。</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第6条.費用の負担</h3>
                        <p className="terms-text">本サービスの利用にあたって必要な端末機器等の購入または維持に要する費用、パケット通信料等のインターネット接続に要する通信料、電気料金その他本サービスを利用する上で必要となる一切の費用は、ユーザーが負担するものとします。</p>
                    </div>
                    <div>
                        <h3 className="terms-heading">第7条.本サービスの提供の停止等</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">
                            当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。ユーザーに対して本サービスの全部の提供が停止された場合、当該ユーザーとの本サービス利用契約は終了するものとします。
                                <ul className="terms-abc-list">
                                    <li>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
                                    <li>第18条が定める不可抗力事由により、本サービスの提供が困難となった場合</li>
                                    <li>コンピュータまたは通信回線等が事故により停止した場合</li>
                                    <li>本サービスが利用しているクラウドサービスが停止した場合</li>
                                    <li>ユーザーまたは第三者の安全を確保する場合または公共の利益のために緊急を要する場合</li>
                                    <li>当社が経営上の理由から本サービスの提供を停止または中断すると判断した場合</li>
                                    <li>その他、当社が本サービスの提供が困難と判断した場合</li>
                                </ul>
                            </li>
                            <li className="terms-text">当社は、本サービスの提供の停止または中断によりユーザーに生じた損害または不利益について、責任を負わないものとします。</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第8条.権利帰属等</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">
                                当社ウェブサイト、当社アプリケーション及び本サービス(ユーザーコンテンツを除きます。)に関する知的財産権その他の権利は、他からの引用や許可を受けた上で転載しているものを除き、すべて当社または当社にライセンスを許諾している者に帰属します。本規約は、当社ウェブサイト、当社アプリケーションまたは本サービスに関して当社または当社にライセンスを許諾している者の知的財産権を利用許諾するものではありません。
                            </li>
                            <li className="terms-text">
                                ユーザーは、本サービスの内容の全部または一部について、著作権法上認められた利用をする場合であっても、本サービスの個々のページにおいて注記がある場合には、当該注記に従うものとします。
                            </li>
                            <li className="terms-text">
                                登録ユーザーは、自ら知的財産権等の必要な権利を有するか、または権利者から必要な許諾を受けた文章、画像、音声等のコンテンツのみ、本サービスを利用して、ユーザーコンテンツとして投稿または編集できるものとします。
                            </li>
                            <li className="terms-text">
                                ユーザーコンテンツに係る知的財産権等の権利については、当該ユーザーコンテンツを投稿または編集した登録ユーザーまたは当該登録ユーザーが許諾を受けた権利者に留保されるものとします。登録ユーザーは、ユーザーコンテンツが第三者の知的財産権、プライバシー権、名誉権その他の権利または利益を侵害しないものであることを自らの責任で判断するものとし、ユーザーコンテンツに関する一切の責任を負担するものとします。登録ユーザーは、ユーザーコンテンツに関して第三者との間で紛争が発生した場合には、自らの費用と責任で解決するものとします。
                            </li>
                            <li className="terms-text">
                                ユーザーは、他の登録ユーザーが投稿または編集したユーザーコンテンツについて、権利者の許可を得ることなく、無断で転載または二次配布等を行うことはできません。
                            </li>
                            <li className="terms-text">
                                登録ユーザーは、自らが投稿または編集したユーザーコンテンツについて、当社が当社ウェブサイトまたは当社アプリケーションに掲載するために複製、変更、翻案、または翻訳しそれらを配信すること(公衆送信及び送信可能化することを含みます)を、地域、目的、時期および方法の制限なく許諾するものとし、これらの行為に対して著作者人格権を行使しないものとします。また、当社は、ユーザーコンテンツの内容が本規約に違反すると判断した場合その他本サービスの運営上合理的に必要と判断した場合、事前の通知なく、当該ユーザーコンテンツを変更または削除することができるものとします。
                            </li>
                            <li className="terms-text">
                                当社ウェブサイトは原則リンクフリーです。ユーザーが、当社ウェブサイトへのリンクを設定する際は、RECOVERY STORYへのリンクであることを明示するものとします。ただし、本サービスの個々のページにおいて注記がある場合には当該注記に従うものとします。
                            </li>
                            <li className="terms-text">
                                本条により当社に許諾されたユーザーコンテンツに関する権利は、当社とユーザーの間の本サービス利用契約が終了した後も引き続きその効力を保持するものとします。
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第9条.アカウント登録の抹消</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">登録ユーザーは、当社所定の手続の完了により、いつでもアカウント登録を抹消することができます。</li>
                            <li className="terms-text">
                                当社は、以下の各号のいずれかに該当する場合は、事前に通知または催告することなく、ユーザーコンテンツその他の登録ユーザーが投稿したデータを削除もしくは非表示にし、当該登録ユーザーについて本サービスの利用を一時的または完全に停止し、または登録アカウントに関わるデータを削除し、または登録ユーザーとしての登録を抹消することができるものとします。本サービスの利用が完全に停止された場合、本サービス利用契約は解除されるものとします。
                                <ul className="terms-abc-list">
                                    <li>第3条第2項各号に該当する事由が存在すると当社が判断した場合</li>
                                    <li>第5条第1項各号に規定する行為をユーザーが行った場合</li>
                                    <li>前二号のほか、ユーザーが本規約に違反した場合</li>
                                    <li>その他、当社が本サービスの利用を適当ではないと判断した場合</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第10条.アカウント登録の抹消に伴う措置</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">当社は、前項に基づき登録ユーザーのアカウント登録が抹消された場合、直ちに、当該登録ユーザーのアカウント情報、ユーザーコンテンツその他の当該アカウントに紐付くデータの一切を削除するか登録ユーザーによるアクセスを遮断することができるものとします。当社が当該データを削除しない場合、当該データの取り扱いには引き続き第17条が適用されるものとします。</li>
                            <li className="terms-text">前項に基づく措置その他登録抹消に伴いユーザーに生じる損失または不利益について、当社は責任を負わないものとします。</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第11条.保証の否認</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">
                                当社は、本サービスの完全性、妥当性、有用性、ユーザーの利用目的及び環境との適合性、品質その他本サービスに関する欠点がないこと、本コンテンツおよびユーザーコンテンツその他本サービスに関して当社がユーザーに提供した情報が正確であること、本サービスがすべての端末から問題なくアクセスできること、本サービスがすべての地域で利用可能であること、本サービスが第三者の権利を侵害しないこと、本サービスについて中断、中止その他の障害が生じないこと、バグ、脆弱性又は不具合が生じないことおよび情報セキュリティ事故が生じないこと等について、一切保証をするものではありません。
                            </li>
                            <li className="terms-text">
                                当社は、本コンテンツ、ユーザーコンテンツその他の本サービス上でユーザーに対して提供され、または、用いられるいかなる情報についても、ユーザーが当該情報を利用し、または、利用できなかったことによって生じる損害について、一切の保証をしません。
                            </li>
                            <li className="terms-text">
                                当社は、本サービスの利用に起因して生じたユーザー間またはユーザーと第三者との間の紛争に関し、一切の責任を負わないものとします。また、ユーザーが本サービスに関連して他のユーザーやその他の第三者からクレームを受けまたはそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するとともに、ユーザーの費用と責任において当該クレームまたは紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
                            </li>
                            <li className="terms-text">
                                当社は、本サービスに対する不正アクセス、コンピューターウイルスの侵入等、第三者の行為に起因してユーザーに生じる損害について、一切の責任を負わないものとします。
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第12条.当社の責任範囲</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">
                                当社は、本サービスに関連して第11条で保証が否認されている事項または第18条が規定する不可抗力事由に起因してユーザーに損害が発生した場合、一切の責任を負いません。ただし、このような非保証事由や不可抗力事由と本サービスに関する当社の故意または過失によるユーザーの権利侵害の双方が損害発生の原因である場合、当該権利損害に係る責任については次項が適用されます。
                            </li>
                            <li className="terms-text">
                                本サービスに関連して当社の故意または過失によるユーザーの権利侵害によってユーザーに損害が生じた場合でも、当社が負うべき損害賠償責任は、当社に故意または重過失がある場合を除き、当該義務違反から直接かつ現実に発生した物的損害の範囲に限定され、逸失利益、精神的損害、特別損害、間接損害等は賠償されないものとします。
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第13条.差止め、ユーザーによる損害賠償</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">
                                当社は、ユーザーが本規約に反する行為をした場合、当該行為を差し止めることができるものとします。
                            </li>
                            <li className="terms-text">
                                ユーザーは、本規約に定める条項に違反し、または、自己の責めに帰すべき事由により当社に損害を与えた場合、当社に対し、当社が被った損害(合理的な弁護士費用を含みます。)を賠償するものとします。
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第14条.権利義務の譲渡禁止</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">
                                ユーザーは、本規約に基づく本サービス利用契約上の地位または権利もしくは義務について、第三者に対して譲渡、移転、その他の処分をしてはならないものとします。
                            </li>
                            <li className="terms-text">
                                当社は、本サービスに係る事業を第三者に譲渡することができ、譲渡した場合には本サービス利用契約上の地位、本規約に基づく権利及び義務並びに登録ユーザーのアカウント情報その他の情報を譲受人に譲渡または提供することができるものとし、登録ユーザーは、当該譲渡または提供にあらかじめ同意するものとします。なお、当該譲渡は、事業譲渡、会社分割その他事業が移転するあらゆる行為を含みます。
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第15条.通知・連絡</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">
                                本サービスに関する問い合わせその他ユーザーから当社に対する通知または連絡、及び当社からユーザーに対する連絡または通知は、当社の定める方法によって行うものとします。
                            </li>
                            <li className="terms-text">
                                当社が、登録ユーザーのアカウント情報に含まれる連絡先に連絡または通知を行った場合、登録ユーザーは、発信時において、当該連絡または通知を受領したものとみなします。
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第16条.秘密保持</h3>
                        <p className="terms-text">
                            ユーザーは、本サービスに関連して当社がユーザーに対して秘密に取り扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱わなければならないものとします。
                        </p>
                    </div>
                    <div>
                        <h3 className="terms-heading">第17条.利用者に関する情報の取扱い等</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">
                                当社によるユーザーのアカウント情報その他のユーザーの個人情報の取扱いについては、別途当社プライバシーポリシーの定めによるものとし、ユーザーは当該プライバシーポリシーに従って当社がユーザーの個人情報を取扱うことについて同意するものとします。
                            </li>
                            <li className="terms-text">
                                当社は、ユーザーが当社に提供した情報、データ等のうち、前項に従って取り扱う個人情報および第8条に従って取り扱う知的財産権によって保護されるユーザーコンテンツ以外の情報、データ等(当社が統計化した統計情報を含みます。)については、当社の裁量で、自由に利用及び公開することができるものとし、ユーザーはこれに異議を唱えないものとします。
                            </li>
                            <li className="terms-text">
                                当社は、当社ウェブサイトまたは当社アプリケーションにおいて、当社からのご案内または当社の活動に協賛くださる第三者の企業ロゴマーク等のご案内を掲載することがあります。ご案内の内容は、ユーザーが本サービスを利用する際に随時変更される場合があります。
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第18条.不可抗力事由</h3>
                        <p className="terms-text">
                            当社は、天災地変(台風、津波、地震、風水害、落雷及び塩害等を含みますが、これらに限られません。)、火災、感染症、伝染病、疫病、パンデミック（感染防止対策を含みます。）、第三者が提供するシステム又はネットワークの障害・故障、サイバー攻撃、公害、戦争、暴動、内乱、テロ行為、ストライキ、法令又は規則の制定又は改廃、公権力による命令又は処分その他の政府による行為、争議行為、輸送機関の事故その他の不可抗力事由によって本サービスの提供等が妨げられた場合、当該不可抗力事由によってユーザーに生じた損害又は不利益について責任を負いません。
                        </p>
                    </div>
                    <div>
                        <h3 className="terms-heading">第19条.本規約の変更</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">
                                当社は、以下のいずれかに該当する場合、法令の定める範囲内で本規約を変更できるものとします。
                                <ul className="terms-num-sub-list">
                                    <li>本規約の変更が、ユーザーの一般の利益に適合するとき</li>
                                    <li>本規約の変更が、本規約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき</li>
                                </ul>
                            </li>
                            <li className="terms-text">
                                当社は、前項の規定による本規約の変更内容及び効力発生時期を、当社ウェブサイト上または当社アプリケーション上に掲載する方法その他適切な方法によりユーザーに周知するものとします。
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第20条.完全合意・分離可能性・存続効</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">
                                本規約は、本規約に含まれる事項に関する当社とユーザーとの完全な合意を構成し、口頭又は書面を問わず、本規約に含まれる事項に関する当社とユーザーとの事前の合意、表明及び了解に優先するものとします。
                            </li>
                            <li className="terms-text">
                                本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効と判断された場合であっても、本規約の残りの規定及び一部が無効と判断された規定の残りの部分は、継続して効力を有します。
                            </li>
                            <li className="terms-text">
                                本規約の全部または一部の定めが無効とされうる場合であっても、当該定めはこれが有効とされるように合理的かつ限定的に解釈されます。
                            </li>
                            <li className="terms-text">
                                本サービス利用契約が終了した場合でも、第4条、第5条、第7条第2項、第8条、第10条から第14条、第16条から第21条は引き続き効力を有するものとします。
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">第21条.準拠法・裁判管轄</h3>
                        <p className="terms-text">
                            本規約の解釈にあたっては、日本法を準拠法とします。本サービスに関して紛争が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
                        </p>
                    </div>
                    <div className="terms-right-text">
                        <p className="terms-text">以上<br />2024年2月27日制定</p>
                    </div>
                </div>
            </div>
        </HelmetProvider>
    )
};

export default Privacypolicy;