import { Helmet } from "react-helmet-async";

type Props = {
    metaData : {
        title : string,
        description : {
            name : string,
            content : string
        },
        canonical : {
            rel : string,
            href : string
        },
        ogpDatas : {
            property : string,
            content : string
        }[],
        xDatas : {
            name : string,
            content : string
        }[]
    };
};

const HeadBlock = (props: Props) => {
    return (
        <Helmet>
            <title>{props.metaData.title}</title>
            <meta
                name={props.metaData.description.name}
                content={props.metaData.description.content}
            />
            {props.metaData.ogpDatas.map((ogpData, i) => (
                <meta key={i} property={ogpData.property} content={ogpData.content} />
            ))}
            {props.metaData.xDatas.map((xData, i) => (
                <meta key={i} name={xData.name} content={xData.content} />
            ))}
            <link rel={props.metaData.canonical.rel} href={props.metaData.canonical.href} />
        </Helmet>
    )
};
  
export default HeadBlock;