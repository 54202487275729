type Props = {
    img : string;
}

const TalentVoicePlayImg = (props: Props) => (
    <section className="talent-voice-media-img-section">
        <div className="talent-voice-media-img-wrap">
            <div className="talent-voice-media-img" style={{backgroundImage: "url(/images/" + props.img + ")"}}></div>
        </div>
    </section>
);
  
export default TalentVoicePlayImg;