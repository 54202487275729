import { Link } from "react-router-dom";

import TalentListItem from "./TalentListItem";
import talentInfo from "../json/talentInfo.json";

type TalentItem = {
    fullName : string;
    firstName : string;
    lastName : string;
    lowerCaseName : string;
    mainImg : string;
    text : string;
};

const Talent = () => {
    const talents: TalentItem[] = talentInfo.data;

    return (
        <section className="talent-section">
            <ul className="talent-list">
                {talents.map((talent: TalentItem, i) => {
                    const talentMainImg = {backgroundImage: "url(/images/" + talent.mainImg + ")"};

                    return (
                        <li
                            key={i}
                            className={talent.lowerCaseName}
                            style={talent.mainImg ? talentMainImg : undefined}
                        >
                            {
                                talent.text ? 
                                <Link to={"/" + talent.lowerCaseName} className="talent-link">
                                    <span className="talent-list-num">#</span>
                                    <TalentListItem
                                        fullName={talent.fullName}
                                        firstName={talent.firstName}
                                        lastName={talent.lastName}
                                        text={talent.text}
                                    />
                                </Link>
                                :
                                <TalentListItem
                                    fullName={talent.fullName}
                                    firstName={talent.firstName}
                                    lastName={talent.lastName}
                                    text={talent.text}
                                />
                            }
                        </li>
                    );
                })}
            </ul>
        </section>
    );
};
  
export default Talent;