import { useCallback } from 'react';

export const Categories = {
    AudioPlayer: 'AudioPlayer'
};

export function useTracking() {
    const gtag = window.gtag;

    const pageview = useCallback(
        (pagePath) => {
            if (!gtag) return;

            gtag('config', 'G-51QGELW9RF', { page_path: pagePath });
        },
        [gtag]
    );

    const event = useCallback(
        (action, category, label) => {
            if (!gtag) return;

            const options = {};
            if (category) {
                options.event_category = category;
            }
            if (label) {
                options.event_label = label;
            }

            gtag('event', action, options);
        },
        [gtag]
    );

    return { gtag, pageview, event };
}