
type FooterLinkItem = {
    img : string;
    alt : string;
    link : string;
};

const FooterLinkList = () => {    
    const footerLinks: FooterLinkItem[] = [
        // { img: "/images/icon_facebook.svg", alt: "facebook", link: "" },
        // { img: "/images/icon_instagram.svg", alt: "instagram", link: "" },
        // { img: "/images/icon_line.svg", alt: "line", link: "" },
        // { img: "/images/icon_x.svg", alt: "x", link: "" },
        { img: "/images/icon_spotify.png", alt: "spotify", link: "https://open.spotify.com/show/2ftxt94bNKljmOM5okzLLS?si=0b9667fe6ef3460e" }
    ];

    return (
        <ul className="footer-link-list">
            {footerLinks.map((footerLink: FooterLinkItem, i) => {
                return (
                    <li key={i}>
                        <a href={footerLink.link} className="footer-link"  target="_blank" rel="noreferrer noopener">
                            <img src={footerLink.img} alt={footerLink.alt} />
                        </a>
                    </li>
                );
            })}
        </ul>
    );
};

export default FooterLinkList;