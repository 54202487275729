import SectionTitle from "./SectionTitle";
import TalentEpisodeList from "./TalentEpisodeList";

type Props = {
    fullName : string;
    firstName : string;
    no : number;
    lowerCaseName : string;
    cassetteData : {
        no: number,
        img : string,
        date : string,
        title : string,
        text : string,
        voiceTime : string,
        mediaLinks : {
            platform : string,
            url : string
        }[]
    }[];
}

const TalentEpisode = (props: Props) => (
    <section className="talent-episode-section">
        <SectionTitle 
            text={"ストーリー"}
        />
        <TalentEpisodeList
            fullName={props.fullName}
            firstName={props.firstName}
            no={props.no}
            lowerCaseName={props.lowerCaseName}
            cassetteData={props.cassetteData}
        />
    </section>
);
  
export default TalentEpisode;