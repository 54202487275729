import ScrollIcon from "./ScrollIcon";

const Kv = () => (
    <section className="kv-section">
        <h1 className="primary-txt">
            RECOVERY<br />STORY
        </h1>
        <ScrollIcon />
    </section>
);

export default Kv;