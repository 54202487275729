import { Link } from "react-router-dom";

import TalentVoicePlayAudio from "./TalentVoicePlayAudio";
import TalentVoiceInfoText from "./TalentVoiceInfoText";
import TalentPlatformLinkList from "./TalentPlatformLinkList";

type Props = {
    fullName : string;
    lowerCaseName : string;
    no : number;
    date : string;
    title : string;
    text : string;
    textLinks: {
        link : string,
        text : string
    }[];
    audio : string;
    voiceTime : string;
    mediaLinks : {
        platform : string,
        url : string
    }[];
    chapters : {
        time : string
    }[];
    timeUpEvent : (time : number) => void; 
}

const TalentVoicePlayInfo = (props: Props) => {
    let linkFlg = false;

    props.textLinks.map((textLink) => {
        if(textLink.link) linkFlg = true;
    })
    
    return (
        <section className="talent-voice-info-section">
            <div className="talent-voice-info-wrap">
                <h2 className="talent-voice-info-title">{props.title}</h2>
                <Link to={"/" + props.lowerCaseName} className="talent-voice-info-name">{props.fullName}</Link>
                <div className="talent-voice-info-detail-wrap">
                    <p>{props.date}</p>
                    <p>・{props.voiceTime}</p>
                </div>
                <TalentVoicePlayAudio
                    lowerCaseName={props.lowerCaseName}
                    no={props.no}
                    audio={props.audio}
                    chapters={props.chapters}
                    timeUpEvent={props.timeUpEvent}
                />
                <TalentPlatformLinkList
                    mediaLinks={props.mediaLinks}
                />
                {
                    props.text || linkFlg ? 
                    <TalentVoiceInfoText
                        text={props.text}
                        textLinks={props.textLinks}
                    />
                    : null
                }
            </div>
        </section>
    );
}
  
export default TalentVoicePlayInfo;