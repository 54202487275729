type Props = {
    about: string;
}

const TalentPageMessage = (props: Props) => {
    const aboutTxtArray = props.about.split('\n');
    const aboutTxts = aboutTxtArray.map((aboutTxt: string, i) => (
        <span key={i}>{aboutTxt}<br /></span>
    ));
    return (
        <section className="talent-about-txt-section">
            <div className="talent-about-txt">
                <p>{aboutTxts}</p>
            </div>
        </section>
    );
};
  
export default TalentPageMessage;