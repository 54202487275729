type Props = {
    title: string;
    clickEvent : (opened : boolean) => void; 
}

const CommonBtn = (props: Props) => {
    const opened = true;
    const clickEvent = () => {
        document.body.style.overflow = "hidden";
        props.clickEvent(opened);
    };
    return (
        <button className="common-btn" onClick={() => clickEvent()}>{props.title}</button>
    );
};
  
export default CommonBtn;