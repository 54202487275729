import TalentEpisodeCassette from "./TalentEpisodeCassette";

type Props = {
    fullName : string;
    firstName : string;
    no : number;
    lowerCaseName : string;
    cassetteData : {
        no: number,
        img : string,
        date : string,
        title : string,
        text : string,
        voiceTime : string,
        mediaLinks : {
            platform : string,
            url : string
        }[]
    }[];
}

const TalentEpisodeList = (props: Props) => (
    <ul className="talent-episode-list">
        {props.cassetteData.map((talentEpisode, i) => {
            if(props.no !== talentEpisode.no) {
                return (
                    <TalentEpisodeCassette
                        key={i}
                        fullName={props.fullName}
                        firstName={props.firstName}
                        lowerCaseName={props.lowerCaseName}
                        no={talentEpisode.no}
                        img={talentEpisode.img}
                        date={talentEpisode.date}
                        title={talentEpisode.title}
                        text={talentEpisode.text}
                        voiceTime={talentEpisode.voiceTime}
                        mediaLinks={talentEpisode.mediaLinks}
                    />
                )
            } else {
                return null;
            }
        })}
    </ul>
);
  
export default TalentEpisodeList;