import { useState } from "react";

import CommonBtn from "./CommonBtn";
import TalentVoiceMessageList from "./TalentVoiceMessageList";

type Props = {
    chapters : {
        no : string,
        time : string,
        text : string
    }[];
    time : number;
}

const TalentMessageBtnWrap = (props: Props) => {
    const [opened, setOpened] = useState(false);
    const toggleMessageList = (newValue: any) => {
        setOpened(newValue);
    };
    return (
        <section className="talent-message-btn-section">
            <CommonBtn title="チャプター" clickEvent={toggleMessageList} />
            <TalentVoiceMessageList
                title="チャプター"
                chapters={props.chapters}
                opened={opened}
                clickEvent={toggleMessageList}
                time={props.time}
            />
        </section>
    );
};
  
export default TalentMessageBtnWrap;