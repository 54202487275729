import FixedContents from "./FixedContents";

const Message = () => (
    <section className="message-section">
        <FixedContents />
        <div className="message-wrap">
            <h2 className="message-heading-txt">
                だれかのストーリーは、<br />
                わたしのストーリーでもある。
            </h2>
            <p className="message-txt">
                リカバリーストーリーは<br />様々な人に<br />悩みから立ち直った経験や<br />考え方の工夫<br />日頃のストレス予防法や解消法を<br />自由に話してもらう<br />コンテンツです。
            </p>
        </div>
    </section>
);
  
export default Message;