import { useRef, useState } from "react";
import { useTracking, Categories } from "../utils/ga";

type Props = {
    lowerCaseName : string;
    no : number;
    audio : string;
    chapters : {
        time : string
    }[];
    timeUpEvent : (time : number) => void; 
}

const TalentVoicePlayAudio = (props: Props) => {
    const refAudio = useRef<HTMLAudioElement>(null!);
    const [firstPlayFlg, setFirstPlayFlg] = useState(true);
    const [firstStopFlg, setFirstStopFlg] = useState(true);
    const { event } = useTracking();
    let array: number[] = [];
    let beforeCurrentTime: number;

    props.chapters.map((chapter) => {
        const [minutes, seconds] = chapter.time.split(':');
        const time = Number(minutes) * 60 + Number(seconds);
        array.push(time);
    });

    const handleAudioProgress = () => {
        if(firstPlayFlg) {
            setFirstPlayFlg(false);
            event('play', Categories.AudioPlayer, props.lowerCaseName + '-story' + props.no);
        }
    };
    const handleAudioStop = () => {
        if(firstStopFlg) {
            setFirstStopFlg(false);
            event('complete', Categories.AudioPlayer, props.lowerCaseName + '-story' + props.no);
        }
    };
    const handleTimeUpdate = () => {
        const currentTime = Math.round(refAudio.current.currentTime);
        array.map((item) => {
            if(item <= currentTime && beforeCurrentTime !== currentTime) {
                beforeCurrentTime = currentTime;
                props.timeUpEvent(currentTime);
            }
        });
    };
    
    return (
        <div className="talent-voice-audio-wrap">
            <audio
                key={props.no}
                className="talent-voice-audio"
                controlsList={"nodownload"}
                controls
                ref={refAudio}
                onPlaying={handleAudioProgress}
                onEnded={handleAudioStop}
                onTimeUpdate={handleTimeUpdate}
            >
                <source src={"/media/" + props.audio} type="audio/mpeg" />
            </audio>
        </div>
    );
}
  
export default TalentVoicePlayAudio;