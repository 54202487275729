import { useEffect } from "react";

type Props = {
    title: string;
    chapters : {
        no : string,
        time : string,
        text : string
    }[];
    opened: boolean;
    clickEvent : (opened : boolean) => void; 
    time : number;
}

const TalentVoiceMessageList = (props: Props) => {
    let array: number[] = [];
    props.chapters.map((chapter) => {
        const [minutes, seconds] = chapter.time.split(':');
        const time = Number(minutes) * 60 + Number(seconds);
        array.push(time);
    });

    const opened = false;
    const clickEvent = () => {
        const element = document.getElementsByClassName("talent-voice-message-list-wrap")[0] as HTMLElement;

        document.body.style.overflow = "auto";
        element.style.display = "block";
        props.clickEvent(opened);

    };

    const animationEndHandler = () => {
        const element = document.getElementsByClassName("talent-voice-message-list-wrap")[0] as HTMLElement;
        const listElement = document.getElementsByClassName("talent-voice-message-list-inner")[0] as HTMLElement;
        const listHeight = element.getBoundingClientRect().height - 70;

        listElement.style.height = listHeight + "px";

        if(element.classList.contains("isClosed")) {
            element.style.display = "none";
            return;
        }
    }

    useEffect(() => {
        const element = document.getElementsByClassName("talent-voice-message-list-wrap")[0] as HTMLElement;
        element.addEventListener("animationend", animationEndHandler);
        return () => {
            window.removeEventListener("animationend", animationEndHandler);
        }
    }, []);
    return (
        <div className={"talent-voice-message-list-wrap" + (props.opened ? " isOpened" : " isClosed")} style={{ display: "none" }}>
            <button className="talent-voice-message-list-btn" onClick={() => clickEvent()}>{props.title}</button>
            <div className="talent-voice-message-list-inner">
                <ul className="talent-voice-message-list">
                    {props.chapters.map((chapter, i) => {
                        let chapterFlg = false;
                        if(array[i] <= props.time && array[i + 1] > props.time || array[i] <= props.time && i === props.chapters.length - 1) {
                            chapterFlg = true;
                        }
                        return (
                            <li key={i} className={"talent-voice-message-list-item" + (chapterFlg ? " isPlaying" : "")}>
                                <div className="talent-voice-message-list-item-wrap">
                                    <span className="talent-voice-message-list-item-time">{chapter.time}</span>
                                    <span className="talent-voice-message-list-item-num">{chapter.no}</span>
                                </div>
                                <p className="talent-voice-message-list-item-text">{chapter.text}</p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};
  
export default TalentVoiceMessageList;