import { Link } from "react-router-dom";

import TalentPlatformLinkList from "./TalentPlatformLinkList";

type Props = {
    fullName : string;
    firstName : string;
    lowerCaseName : string;
    no : number;
    img : string;
    date : string;
    title : string;
    text : string;
    voiceTime : string;
    mediaLinks : {
        platform : string,
        url : string
    }[];
}

const TalentEpisodeCassette = (props: Props) => {
    return (
        <li>
            <Link to={"/" + props.lowerCaseName + "/story" + props.no} className="talent-episode-cassette">
                <div className="talent-episode-cassette-img">
                    <img src={"/images/" + props.img} alt={props.fullName + props.no} />
                </div>
                <div className="talent-episode-cassette-wrap">
                    <div className="talent-episode-cassette-head">
                        <p className="talent-episode-cassette-name">{props.fullName}</p>
                        <p className="talent-episode-cassette-date">{props.date}</p>
                    </div>
                    <h4 className="talent-episode-cassette-title">{props.title}</h4>
                    {
                        props.text ?
                        <p className="talent-episode-cassette-text">{props.text}</p>
                        : null
                    }
                    <p className="talent-episode-cassette-time">{props.voiceTime}</p>
                </div>
            </Link>
            <TalentPlatformLinkList
                mediaLinks={props.mediaLinks}
            />
        </li>
    );
};
  
export default TalentEpisodeCassette;