import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useTracking } from "../utils/ga";

import HeadBlock from "./HeadBlock";
import Header from "./Header";
import TalentPageTop from "./TalentPageTop";
import TalentPageMessage from "./TalentPageMessage";
import TalentEpisode from "./TalentEpisode";

type Props = {
    fullName : string;
    firstName : string;
    lastName : string;
    lowerCaseName : string;
    mainImg : string;
    about : string;
    cassetteData : {
        no: number,
        img : string,
        date : string,
        title : string,
        text : string,
        voiceTime : string,
        mediaLinks : {
            platform : string,
            url : string
        }[]
    }[];
    metaData : {
        title : string,
        description : {
            name : string,
            content : string
        },
        canonical : {
            rel : string,
            href : string
        },
        ogpDatas : {
            property : string,
            content : string
        }[],
        xDatas : {
            name : string,
            content : string
        }[]
    };
};

const TalentInfo = (props: Props) => {
    const { pathname } = useLocation();
    const { gtag, pageview } = useTracking();
    
    useEffect(() => {
        pageview(pathname);
    }, [pageview, pathname]);

    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <HelmetProvider>
            <HeadBlock
                metaData={props.metaData}
            />
            <Header />
            <TalentPageTop
                firstName={props.firstName}
                lastName={props.lastName}
                lowerCaseName={props.lowerCaseName}
                mainImg={props.mainImg}
                cassetteData={props.cassetteData}
            />
            <TalentPageMessage
                about={props.about}
            />
            <TalentEpisode
                fullName={props.fullName}
                firstName={props.firstName}
                no={0}
                lowerCaseName={props.lowerCaseName}
                cassetteData={props.cassetteData}
            />
        </HelmetProvider>
    )
};

export default TalentInfo;