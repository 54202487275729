import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useTracking } from "../utils/ga";

import HeadBlock from "./HeadBlock";
import Kv from "./Kv";
import Message from "./Message";
import Talent from "./Talent";

type Props = {
    metaData : {
        title : string,
        description : {
            name : string,
            content : string
        },
        canonical : {
            rel : string,
            href : string
        },
        ogpDatas : {
            property : string,
            content : string
        }[],
        xDatas : {
            name : string,
            content : string
        }[]
    };
};

const Home = (props: Props) => {
    const { pathname } = useLocation();
    const { pageview } = useTracking();

    useEffect(() => {
        pageview(pathname);
    }, [pageview, pathname]);

    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <HelmetProvider>
            <HeadBlock
                metaData={props.metaData}
            />
            <Kv />
            <Message />
            <Talent />
        </HelmetProvider>
    )
};
  
export default Home;