import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useTracking } from "../utils/ga";

import HeadBlock from "./HeadBlock";
import Header from "./Header";
import FixedContents from "./FixedContents";

const metaData = {
    title : "プライバシーポリシー / RECOVERY STORY（リカバリーストーリー）",
    description : {
        name : "description",
        content : "RECOVERY STORY（リカバリーストーリー）は、ストレスがたまり気分が落ち込んだ時の乗り越え方や考え方の工夫、ストレス解消法の体験記の音声コンテンツ配信サービスです"
    },
    canonical : {
        rel: "canonical",
        href: "https://recoverystory.com/privacypolicy"
    },
    ogpDatas : [
        { property: "og:url", content: "https://recoverystory.com/privacypolicy" },
        { property: "og:title", content: "プライバシーポリシー / RECOVERY STORY（リカバリーストーリー）" },
        { property: "og:description", content: "RECOVERY STORY（リカバリーストーリー）は、ストレスがたまり気分が落ち込んだ時の乗り越え方や考え方の工夫、ストレス解消法の体験記の音声コンテンツ配信サービスです" }
    ],
    xDatas : [
        { name: "twitter:title", content: "プライバシーポリシー / RECOVERY STORY（リカバリーストーリー）" },
        { name: "twitter:description", content: "RECOVERY STORY（リカバリーストーリー）は、ストレスがたまり気分が落ち込んだ時の乗り越え方や考え方の工夫、ストレス解消法の体験記の音声コンテンツ配信サービスです" }
    ]
}

const Privacypolicy = () => {
    const { pathname } = useLocation();
    const { gtag, pageview } = useTracking();
    
    useEffect(() => {
        pageview(pathname);
    }, [pageview, pathname]);

    useEffect(() => {
        document.body.style.overflow = "auto";
        window.scrollTo(0, 0);
    });
    return (
        <HelmetProvider>
            <HeadBlock metaData={metaData} />
            <Header />
            <FixedContents />
            <div className="terms-wrap">
                <h2 className="terms-main-heading">情報セキュリティ基本方針</h2>
                <div className="terms-text-wrap">
                    <p className="terms-text">
                        NO NAME PROJECT株式会社（以下「当社」といいます）は、情報資産の情報セキュリティを確保することが当社の重要な経営課題と認識し、保有する情報資産を社内外の様々な情報セキュリティ上の脅威から保護し、社会的責任を果たすとともに、当社が提供する全てのサービス（以下「本サービス等」といいます）の利用者や取引各社の皆様に信頼感および安心感を提供することにより、継続的かつ安定的な事業運営を行い、企業価値向上の実現を目指します。そのため、以下の情報セキュリティ方針を定め、これを実施、推進することを宣言します。
                    </p>
                    <ul className="terms-num-list">
                        <li className="terms-text">
                            情報セキュリティに関する法令およびその他の規範を遵守します。
                        </li>
                        <li className="terms-text">
                            当社の事業展開において、情報資産の収集、利用および提供を行う場合、社内関連規程に照らし適正に行います。
                        </li>
                        <li className="terms-text">
                            情報資産への不正アクセス、情報資産の紛失、漏えい、改ざんおよび破壊等の予防ならびに是正に適切な処置を講じます。
                        </li>
                        <li className="terms-text">
                            当社は、役員および業務に従事するすべての者に対して積極的に教育・訓練を行い、個人情報の取扱いを委託する場合は委託先も含めたすべての者に対して情報セキュリティの重要性を認識させ、情報資産の適正な利用を行うよう周知徹底を図ります。
                        </li>
                        <li className="terms-text">
                            当社が取得した情報資産に関する情報セキュリティの遵守状況を点検・評価して定期的に監査を実施し、情報セキュリティの確保に努めるとともに、その結果に基づき、プライバシーポリシーを含めた情報セキュリティポリシーの見直し、運用の継続的な改善を図ります。
                        </li>
                    </ul>
                </div>
                <h2 className="terms-main-heading">プライバシーポリシーおよび外部通信ポリシ－</h2>
                <div className="terms-text-wrap">
                    <p className="terms-text">
                        当社は、本サービス等を通じて取得する本サービス等のユーザーの個人情報を以下の通り取り扱い、以下の第5条に規定する情報をユーザーの端末から取得し外部に送信します。本サービス等をご利用になるためには、本プライバシーポリシーおよび外部通信ポリシー（以下「本ポリシー」といいます。）を熟読していただき、すべての内容に同意していただく必要があります。本ポリシーに同意されない皆様は、本サービス等の利用を中止してください。
                    </p>
                    <div>
                        <h3 className="terms-heading">1. 当社が取得するユーザーに関する情報</h3>
                        <p className="terms-text">
                            本サービス等において、当社は、以下のユーザーに関する情報を取得することがあります。
                        </p>
                        <ul className="terms-num-sub-list">
                            <li className="terms-text">
                                本サービス等を通じて取得する情報<br />
                                当社は、ユーザーが自ら入力した氏名、年齢、性別、住所、電話番号、プロフィール情報、メールアドレス、所属団体等の情報を取得します。また、当社は、ユーザーから寄せられた質問への回答や投稿内容、リアクション・シェア・お気に入りの情報、当社に対するお問い合わせに関する情報を取得します。
                            </li>
                            <li className="terms-text">
                                本サービス等の利用において、他のサービスと連携を許可することにより、当該他のサービスから提供される情報<br />
                                当社は、ユーザーが本サービス等のアカウントを第三者サービス（Facebook、X、Google、Spotify、Amazon music、Apple Podcast等）のアカウントと連携および接続する場合、連携した第三者サービスのID、プロフィール情報等を取得します。
                            </li>
                            <li className="terms-text">
                                電子メール等の手段によるユーザーとの連絡から取得する情報<br />
                                当社は、ユーザーが電子メール等の手段によって当社と連絡する過程で当社に提供する情報を取得します。
                            </li>
                            <li className="terms-text">
                                ユーザーが本サービス等や当社ウェブサイト、当社アプリケーション等にアクセスした際に自動的に取得する情報<br />
                                当社は、ユーザーの本サービス等のご利用状況に関する情報を自動的に収集します。当該情報には、ユーザーの端末から自動的に送信される利用環境や利用状況・履歴に関する情報等（端末識別子、広告ID、IPアドレス、クッキー情報、位置情報、OSの種類、ブラウザの種類、ブラウザの言語、ランディングページ、ページ閲覧数、クリック数、閲覧順序、閲覧時間、検索ワードその他第5条に規定する情報）が含まれます。
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">2. 個人情報の取得・利用目的</h3>
                        <p className="terms-text">
                            当社は、第1条に規定したユーザーに関する情報のうち個人情報に該当するものを、以下の目的で適法かつ公正な手段で取得・利用します。当社は、以下の目的達成に必要な範囲を超えて、取得した個人情報を利用しません。
                        </p>
                        <ul className="terms-num-sub-list">
                            <li className="terms-text">
                                本サービス等におけるコンテンツの提供、本サービス等への登録、本サービス等利用時の本人認証等、本サービス等の提供、維持および管理のため
                            </li>
                            <li className="terms-text">
                                本サービス等に関するご案内、お問い合わせ等への対応のため
                            </li>
                            <li className="terms-text">
                                本サービス等の安全性を確保するため（システムメンテナンス、不具合対応を含みます）
                            </li>
                            <li className="terms-text">
                                本サービス等に関する当社の規約、ポリシー等に違反する行為に対する対応のため
                            </li>
                            <li className="terms-text">
                                本サービス等に関する当社の規約、ポリシー等の変更等を通知するため
                            </li>
                            <li className="terms-text">
                                アンケートの実施のため
                            </li>
                            <li className="terms-text">
                                キャンペーン実施のため
                            </li>
                            <li className="terms-text">
                                当社ウェブサイト、当社アプリケーションその他の本サービス等の利用状況等をデータ集積して調査および分析することにより、個人情報を取得した本サービス等やその他のサービスの品質向上、改善、開発を行うため
                            </li>
                            <li className="terms-text">
                                本サービス等に表示するコンテンツをユーザーの興味やニーズに適したものとする（パーソナライズする）ため
                            </li>
                            <li className="terms-text">
                                個人情報を取得した本サービス等やその他のサービスのご案内（パーソナライズされたものを含みます）の作成、送信、配信および効果測定のため
                            </li>
                            <li className="terms-text">
                                その他当社がユーザーに対して別途通知したもしくは公表した又はユーザーが同意した目的のため
                            </li>
                            <li className="terms-text">
                                上記11項目に附随する目的のため
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">3. 個人情報の管理</h3>
                        <p className="terms-text">
                            当社は、個人情報の滅失、毀損、漏洩および不正利用等を防止し、その安全管理を行うために必要な措置を講じ、個人情報保護の担当者を設置し、個人情報を安全に管理します。また、当社は個人情報を、利用目的のために必要な範囲内において、正確かつ最新の状態で管理するように努めます。当社が実施している安全管理措置の詳細についてご質問等がある場合は、第7条のお問い合わせ先にご連絡下さい。
                        </p>
                    </div>
                    <div>
                        <h3 className="terms-heading">4. 個人データの第三者への提供</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">
                                当社は、個人データを第三者に提供する場合は、あらかじめユーザー本人に同意を得て行います。但し、以下のいずれかに該当する場合は、この限りではありません。
                                <ul className="terms-num-sub-list">
                                    <li>
                                        法令に基づく場合
                                    </li>
                                    <li>
                                        人命、身体又は財産の保護のために必要がある場合であって、ユーザー本人の同意を得ることが困難であるとき
                                    </li>
                                    <li>
                                        公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、ユーザー本人の同意を得ることが困難であるとき
                                    </li>
                                    <li>
                                        国の機関若しくは地方公共団第又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザー本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                                    </li>
                                    <li>
                                        その他法令で認められる場合
                                    </li>
                                </ul>
                            </li>
                            <li className="terms-text">
                                上記1にかかわらず、以下の場合は、当社から当該個人データの提供を受ける者は第三者に該当しないため、個人データの提供のためにユーザー本人の同意を取得する必要はないものとします。
                                <ul className="terms-num-sub-list">
                                    <li>
                                        当社が、利用目的の達成に必要な範囲で、個人データの取扱いの全部もしくは一部を委託する場合(この場合、当社は、個人データを適正に取り扱っていると認められる者を選定し、当該委託先との間で適切な内容の秘密保持契約等を締結することにより個人データ保護に必要な事項を義務付けるとともに、適切な監督を行います。)
                                    </li>
                                    <li>
                                        当社の合併その他の事由による事業の承継に伴って個人データが提供される場合
                                    </li>
                                    <li>
                                        個人データを共同利用する場合であって、あらかじめ共同して利用される個人データの項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報管理責任者の氏名又は名称、住所および代表者の氏名を、本人に通知し、又は本人が容易に知り得る状態に置いている場合
                                    </li>
                                </ul>
                            </li>
                            <li className="terms-text">
                                当社は、外国に所在する第三者に個人データを提供する場合、個人情報保護法が規定する域外移転規制を遵守します。
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">5. 電気通信事業法の外部送信規律に基づく公表事項</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">
                                当社は、本サービス等のユーザーへのサービス利便性の向上、統計データの取得・分析等のため、クッキーを利用します。クッキーとは、ウェブサイトの利用履歴や入力内容等をユーザーのコンピュータにファイルとして保存しておく仕組みであり、ユーザーがブラウザの設定でクッキーの送受信を許可している場合、当社はユーザーのコンピュータに保存されたクッキーを取得し、収集した行動履歴と個人情報を紐付ける場合があります。
                            </li>
                            <li className="terms-text">
                                ユーザーが本サービス等を利用するために当社ウェブサイトにアクセスしたり当社アプリケーションを利用したりする場合、ユーザーのコンピュータから当社以外の第三者（ウェブ解析ツール提供事業者など）へ、クッキー等のユーザーに関する情報が自動的に送信されることがあります。当社が利用している外部送信サービスは以下のとおりです。
                                <div className="terms-table-wrap">
                                    <h4 className="terms-table-name">Googleアナリティクス</h4>
                                    <table className="terms-table">
                                        <tbody>
                                            <tr>
                                                <th>当社の利用目的</th>
                                                <td>
                                                    ・サイトの利便性向上のため<br />
                                                    ・サイトのコンテンツ最適化のため<br />
                                                    ・ユーザーによる閲覧の傾向や履歴の分析のため<br />
                                                    ・ご案内効果の分析のため<br />
                                                    ・サイトやコンテンツの監視のため
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>送信先利用目的</th>
                                                <td>
                                                    ・<a href="https://business.safety.google/intl/ja/tba-jp/">Google「電気通信事業法27条の12に基づく通知・公表のための参考情報（事業者向け）」</a><br />
                                                    ・サービスの提供<br />
                                                    ・サービスの維持、向上<br />
                                                    ・新しいサービスの開発<br />
                                                    ・コンテンツや広告など、カスタマイズしたサービスの提供<br />
                                                    ・パフォーマンスの測定<br />
                                                    ・お客様とのコミュニケーション<br />
                                                    ・Google、Google のユーザー、一般の人々の保護
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>送信される情報</th>
                                                <td>
                                                    ・送信先が利用者を特定するための識別子<br />
                                                    ・クッキーなどデバイスに記録されている識別子<br />
                                                    ・IPアドレス<br />
                                                    ・閲覧したサイトのURLやアプリの画面名<br />
                                                    ・閲覧したサイトやアプリのタイトル<br />
                                                    ・当社訪問の直前に閲覧したサイトのURL<br />
                                                    ・アクセス回数、滞在時間、閲覧日時、流入経路、検索語句<br />
                                                    ・ブラウザやデバイスの種類、バージョン<br />
                                                    ・デバイスの画面サイズ等の閲覧環境の情報
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>送信先の名称および関連するリンク</th>
                                                <td>
                                                    <a href="https://about.google/?_fsi=eRbZ2xxS">Google LLC</a><br />
                                                    <a href="https://policies.google.com/privacy">プライバシーポリシー</a><br />
                                                    <a href="https://support.google.com/analytics/answer/181881">オプトアウト（利用の停止）</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="terms-table-wrap">
                                    <h4 className="terms-table-name">Google タグマネジャー</h4>
                                    <table className="terms-table">
                                        <tbody>
                                            <tr>
                                                <th>当社の利用目的</th>
                                                <td>
                                                    ・ユーザーの使用環境に応じた適切なフォント、画像等の表示のため<br />
                                                    ・コンテンツ管理のため<br />
                                                    ・最適な外部サービスを必要に応じて有効化するため
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>送信先利用目的</th>
                                                <td>
                                                    ・<a href="https://business.safety.google/intl/ja/tba-jp/">Google「電気通信事業法27条の12に基づく通知・公表のための参考情報（事業者向け）」</a><br />
                                                    ・サービスの提供<br />
                                                    ・サービスの維持、向上<br />
                                                    ・新しいサービスの開発<br />
                                                    ・コンテンツや広告など、カスタマイズしたサービスの提供<br />
                                                    ・パフォーマンスの測定<br />
                                                    ・お客様とのコミュニケーション<br />
                                                    ・Google、Google のユーザー、一般の人々の保護
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>送信される情報</th>
                                                <td>
                                                    ・サービス提供会社が利用者を特定するための識別子<br />
                                                    ・クッキーなどデバイスに記録されている識別子<br />
                                                    ・IPアドレス<br />
                                                    ・閲覧したサイトのURLやアプリの画面名<br />
                                                    ・閲覧日時<br />
                                                    ・閲覧したサイトやアプリのタイトル<br />
                                                    ・当社訪問の直前に閲覧したサイトのURL<br />
                                                    ・ブラウザやデバイスの種類、バージョン<br />
                                                    ・デバイスの画面サイズ等の閲覧環境の情報
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>送信先の名称および関連するリンク</th>
                                                <td>
                                                    <a href="https://about.google/?_fsi=eRbZ2xxS">Google LLC</a><br />
                                                    <a href="https://policies.google.com/privacy">プライバシーポリシー</a><br />
                                                    <a href="https://support.google.com/analytics/answer/181881">オプトアウト（利用の停止）</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="terms-table-wrap">
                                    <h4 className="terms-table-name">X広告(旧Twitter)</h4>
                                    <table className="terms-table">
                                        <tbody>
                                            <tr>
                                                <th>当社の利用目的</th>
                                                <td>
                                                    ・ユーザーの行動や属性に基づいたご案内配信のため<br />
                                                    ・ユーザーによる閲覧の傾向や履歴の分析のため<br />
                                                    ・ご案内効果の分析のため<br />
                                                    ・サイトやコンテンツの監視のため
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>送信先利用目的</th>
                                                <td>
                                                    <a href="https://twitter.com/ja/privacy#x-privacy-2">X「情報の利用方法」</a><br />
                                                    ・サービスの運営、改善およびパーソナライズ<br />
                                                    ・安全およびセキュリティの促進<br />
                                                    ・評価、分析<br />
                                                    ・ユーザーと連絡を取る<br />
                                                    ・研究
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>送信される情報</th>
                                                <td>
                                                    ・送信先が利用者を特定するための識別子<br />
                                                    ・クッキーなどデバイスに記録されている識別子<br />
                                                    ・IPアドレス<br />
                                                    ・閲覧したサイトのURLやアプリの画面名<br />
                                                    ・閲覧したサイトやアプリのタイトル<br />
                                                    ・当社訪問の直前に閲覧したサイトのURL<br />
                                                    ・閲覧日時<br />
                                                    ・ブラウザやデバイスの種類、バージョン<br />
                                                    ・デバイスの画面サイズ等の閲覧環境の情報
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>送信先の名称および関連するリンク</th>
                                                <td>
                                                    <a href="https://about.twitter.com/ja">X Corp.</a><br />
                                                    <a href="https://twitter.com/ja/privacy">Xのプライバシーポリシー (twitter.com)</a><br />
                                                    <a href="https://help.twitter.com/ja/safety-and-security/privacy-controls-for-tailored-ads">パーソナライズド広告のプライバシーに関するユーザーのオプション | Xヘルプ (twitter.com)(オプトアウト)</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">6. 個人情報の開示・訂正等</h3>
                        <ul className="terms-num-list">
                            <li className="terms-text">
                                開示<br />
                                本サービス等のユーザーは、当社所定の手続きに従って、個人情報保護法において認められる範囲内において、利用目的の通知を求め、又は個人データもしくは第三者提供記録の開示請求をすることができます。ただし、以下に該当する場合は、開示を行わないことがあります。
                                <ul className="terms-num-sub-list">
                                    <li>
                                        開示することでご本人様又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
                                    </li>
                                    <li>
                                        開示することで当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
                                    </li>
                                    <li>
                                        開示することが法令に違反することとなる場合
                                    </li>
                                    <li>
                                        開示の請求がご本人様からであることが確認できない場合
                                    </li>
                                </ul>
                                なお、利用目的の通知又は個人データもしくは第三者提供記録の開示を請求される場合には、所定の開示手数料をいただく場合があります。
                            </li>
                            <li className="terms-text">
                                訂正等<br />
                                本サービス等のユーザーは、当社所定の手続きに従って、個人データの訂正、追加、削除、利用停止又は消去を請求することができます。これらの請求がなされたときは、当社は、合理的な範囲内で遅滞なく調査を行ない、その結果に基づき、個人情報保護法において認められる範囲内において、当該個人情報の訂正、追加、削除、利用停止又は消去を行い、その旨をユーザーに通知します。なお、訂正、追加、削除、利用停止又は消去を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。
                            </li>
                            <li className="terms-text">
                                請求手続き<br />
                                上記1および2の請求にあたっては、その旨を記載した上で以下の当社のメールアドレス宛にご連絡ください。メールを受信後に、別途ご案内を差し上げます。<br /><br />
                                info(at-mark)nonamepjt.com<br />
                                ※（at-mark）は@と置き換えてください。
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="terms-heading">7. 個人情報取扱事業者に関する情報およびお問い合わせ先</h3>
                        <p className="terms-text">
                            本サービス等における個人情報の取扱い又は本ポリシーに関するご質問・苦情・ご相談等がある場合、以下のお問い合わせ先に記載された当社のメールアドレス宛にご連絡ください。<br /><br />
                            【個人情報に関するお問い合わせ窓口】<br />
                            名称：NO NAME PROJECT株式会社<br />
                            本店所在地：東京都渋谷区渋谷2-12-15　日本薬学会長井記念館<br />
                            代表者名：田代克行<br />
                            お問い合わせ先：info(at-mark)nonamepjt.com<br />
                            ※（at-mark）は@と置き換えてください。
                        </p>
                    </div>
                    <div className="terms-right-text">
                        <p className="terms-text">以上<br />2024年2月27日 制定</p>
                    </div>
                </div>
            </div>
        </HelmetProvider>
    )
};

export default Privacypolicy;