import { Link } from "react-router-dom";

import TalentPlatformLinkList from "./TalentPlatformLinkList";
import FixedContents from "./FixedContents";
import ScrollIcon from "./ScrollIcon";

type Props = {
    firstName : string;
    lastName : string;
    lowerCaseName : string;
    mainImg : string;
    cassetteData : {
        no: number,
        mediaLinks : {
            platform : string,
            url : string
        }[]
    }[];
}

const TalentPageTop = (props: Props) => (
    <section className="talent-page-top-section">
        <FixedContents />
        <div
            className="talent-page-top-wrap"
            style={{backgroundImage: "url(/images/" + props.mainImg + ")"}}
        >
            <div className="talent-media-info-wrap">
                <div className="talent-media-info-inner">
                    <h2 className="primary-txt">{props.lastName}<br />{props.firstName}</h2>
                    <Link to={"/" + props.lowerCaseName + "/story" + props.cassetteData[0].no} className="talent-media-info-link">
                        <img src="/images/btn_new_audio.svg" alt="Play" />
                    </Link>
                </div>
                <TalentPlatformLinkList
                    mediaLinks={props.cassetteData[0].mediaLinks}
                />
            </div>
            <ScrollIcon />
        </div>
    </section>
);
  
export default TalentPageTop;