type Props = {
    fullName : string;
    firstName : string;
    lastName : string;
    text : string;
};

const TalentListItem = (props: Props) => {
    return (
        <div className="talent-info">
            <h3 className="primary-txt">
                {props.lastName}<br />
                {props.firstName}
            </h3>
            {
                props.text ? 
                <>
                    <p className="talent-txt">{props.text}</p>
                    <p className="talent-arrow">MORE</p>
                </>
                : null
            }
        </div>
    )
};

export default TalentListItem;