import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Header = () => {
    const [scrolled, setScrolled] = useState(false);
    let beforeScrollTop = window.pageYOffset;
    const animationToggle = () => {
        const afterScrollTop = window.pageYOffset;
        afterScrollTop > beforeScrollTop ? setScrolled(true) : setScrolled(false);
        beforeScrollTop = afterScrollTop;
    }
    useEffect(() => {
        window.addEventListener('scroll', animationToggle);
        return () => {
            window.removeEventListener('scroll', animationToggle);
        }
    }, []);
    return (
        <header className={"header " + (scrolled ? "isScrolled" : "")}>
            <p className="header-link">
                <button className="header-back-btn" onClick={() => window.history.back()}></button>
                <Link to="/" className="primary-txt">RECOVERY STORY</Link>
            </p>
        </header>
    );
};
  
export default Header;