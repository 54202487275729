import { useRef, useState, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTracking } from "../utils/ga";

type Props = {
    text: string;
    textLinks: {
        link : string,
        text : string
    }[];
}

const TalentVoiceInfoText = (props: Props) => {
    const accordionCloseHeight = 44.8;
    const talentTxt = useRef<HTMLDivElement>(null!);
    const [closed, setClosed] = useState(false);
    const [accordionHeight, setAccordionHeight] = useState(accordionCloseHeight);
    let link;
    let linkText;

    const setAccordionHandler = () => {
        const talentTxtHeight = talentTxt.current.getBoundingClientRect().height;
        talentTxtHeight > accordionCloseHeight ? setClosed(true) : setClosed(false);
    };
    const openAccordion = () => {
        setClosed(!closed);
        setAccordionHeight(talentTxt.current.getBoundingClientRect().height);
    }
    const { pathname } = useLocation();
    const { pageview } = useTracking();
    
    useEffect(() => {
        pageview(pathname);
    }, [pageview, pathname]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener("resize", setAccordionHandler);
        return () => {
            setAccordionHandler();
            window.removeEventListener("resize", setAccordionHandler);
        }
    }, []);

    props.textLinks.map((textLink) => {
        link = textLink.link;
        linkText = textLink.text; 
    });

    return (
        <div className={"talent-voice-info-text-wrap" + (closed ? " isClosed" : "")}>
            <input type="checkbox" id="talent-voice-info-text-check" className="talent-voice-info-text-check" />
            <div className="talent-voice-info-text" style={{height: accordionHeight + "px"}}>
                <p ref={talentTxt}>
                    {props.text}
                    {
                        link ? <a href={link} target="_blank" rel="noopener">{linkText}</a> : null
                    }
                </p>
            </div>
            <div className="talent-voice-info-text-more-btn-wrap">
                <label htmlFor="talent-voice-info-text-check" className="talent-voice-info-text-more-btn" onClick={openAccordion}>もっと読む</label>
            </div>
        </div>
    )
}

export default TalentVoiceInfoText;