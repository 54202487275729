type Props = {
    mediaLinks : {
        platform : string,
        url : string
    }[];
}

const TalentPlatformLinkList = (props: Props) => {
    const stopPropagation = (e: any) => {
        e.stopPropagation();
    }

    return(
        <ul className="talent-platform-list">
            {props.mediaLinks.map((talentplatform, i) => {
                return (
                    <li key={i}>
                        <a href={talentplatform.url} className="talent-platform-link" target="_blank" rel="noreferrer noopener" onClick={stopPropagation}>
                            <img src={"/images/icon_" + talentplatform.platform + "_black.png"} alt={talentplatform.platform} />
                        </a>
                    </li>
                )
            })}
        </ul>
    );
}
  
export default TalentPlatformLinkList;