import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTracking } from "../utils/ga";
import { HelmetProvider } from "react-helmet-async";

import HeadBlock from "./HeadBlock";
import Header from "./Header";
import FixedContents from "./FixedContents";
import TalentVoicePlayImg from "./TalentVoicePlayImg";
import TalentVoicePlayInfo from "./TalentVoicePlayInfo";
import TalentMessageBtnWrap from "./TalentMessageBtnWrap";
import TalentEpisode from "./TalentEpisode";

type Props = {
    fullName : string;
    firstName : string;
    lowerCaseName : string;
    no : number;
    img : string;
    date : string;
    title : string;
    text : string;
    textLinks: {
        link : string,
        text : string
    }[];
    audio : string;
    voiceTime : string;
    mediaLinks : {
        platform : string,
        url : string
    }[];
    cassetteData : {
        no: number,
        img : string,
        date : string,
        title : string,
        text : string,
        voiceTime : string,
        mediaLinks : {
            platform : string,
            url : string
        }[]
    }[];
    chapters : {
        no : string,
        time : string,
        text : string
    }[];
    metaData : {
        title : string,
        description : {
            name : string,
            content : string
        },
        canonical : {
            rel : string,
            href : string
        },
        ogpDatas : {
            property : string,
            content : string
        }[],
        xDatas : {
            name : string,
            content : string
        }[]
    };
}

const TalentVoicePlayPage = (props: Props) => {
    const { pathname } = useLocation();
    const { gtag, pageview } = useTracking();
    
    const [time, setTime] = useState(0);
    const setChapterTime = (chapterTime: any) => {
        setTime(chapterTime);
    };
    useEffect(() => {
        pageview(pathname);
    }, [pageview, pathname]);
    return (
        <HelmetProvider>
            <HeadBlock
                metaData={props.metaData}
            />
            <Header />
            <FixedContents />
            <TalentVoicePlayImg
                img={props.img}
            />
            <TalentVoicePlayInfo
                fullName={props.fullName}
                lowerCaseName={props.lowerCaseName}
                no={props.no}
                date={props.date}
                title={props.title}
                text={props.text}
                textLinks={props.textLinks}
                audio={props.audio}
                voiceTime={props.voiceTime}
                mediaLinks={props.mediaLinks}
                chapters={props.chapters}
                timeUpEvent={setChapterTime}
            />
            <TalentMessageBtnWrap
                chapters={props.chapters}
                time={time}
            />
            <TalentEpisode
                fullName={props.fullName}
                firstName={props.firstName}
                no={props.no}
                lowerCaseName={props.lowerCaseName}
                cassetteData={props.cassetteData}
            />
        </HelmetProvider>
    );
};
  
export default TalentVoicePlayPage;