import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Home from "./components/Home";
import TalentInfo from "./components/TalentInfo";
import TalentVoicePlayPage from "./components/TalentVoicePlayPage";
import Privacypolicy from "./components/Privacypolicy";
import Terms from "./components/Terms";
import Footer from "./components/Footer";
import "./App.css";
import talentInfo from "./json/talentInfo.json";

const metaData = {
    title : "RECOVERY STORY（リカバリーストーリー）",
    description : {
        name : "description",
        content : "RECOVERY STORY（リカバリーストーリー）は、ストレスがたまり気分が落ち込んだ時の乗り越え方や考え方の工夫、ストレス解消法の体験記の音声コンテンツ配信サービスです"
    },
    canonical : {
        rel: "canonical",
        href: "https://recoverystory.com/"
    },
    ogpDatas : [
        { property: "og:url", content: "https://recoverystory.com/" },
        { property: "og:title", content: "RECOVERY STORY（リカバリーストーリー）" },
        { property: "og:description", content: "RECOVERY STORY（リカバリーストーリー）は、ストレスがたまり気分が落ち込んだ時の乗り越え方や考え方の工夫、ストレス解消法の体験記の音声コンテンツ配信サービスです" }
    ],
    xDatas : [
        { name: "twitter:title", content: "RECOVERY STORY（リカバリーストーリー）" },
        { name: "twitter:description", content: "RECOVERY STORY（リカバリーストーリー）は、ストレスがたまり気分が落ち込んだ時の乗り越え方や考え方の工夫、ストレス解消法の体験記の音声コンテンツ配信サービスです" }
    ]
}

function App() {
    const talents = talentInfo.data;

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                    <Home metaData={metaData} />
                } />
                {talents.map((talent, i) => {
                    return (
                        <Route key={i} path={"/" + talent.lowerCaseName} element={
                            <TalentInfo
                                fullName={talent.fullName}
                                firstName={talent.firstName}
                                lastName={talent.lastName}
                                lowerCaseName={talent.lowerCaseName}
                                mainImg={talent.mainImg}
                                about={talent.about}
                                cassetteData={talent.cassetteData}
                                metaData={talent.metaData}
                            />
                        } />
                    );
                })}
                {talents.map((talent) => {
                    return (
                        talent.cassetteData.map((casetteItem, i) => {
                            return (
                                <Route key={i} path={"/" + talent.lowerCaseName + "/story" + casetteItem.no} element={
                                    <TalentVoicePlayPage
                                        fullName={talent.fullName}
                                        firstName={talent.firstName}
                                        lowerCaseName={talent.lowerCaseName}
                                        no={casetteItem.no}
                                        img={casetteItem.img}
                                        date={casetteItem.date}
                                        title={casetteItem.title}
                                        text={casetteItem.text}
                                        textLinks={casetteItem.textLinks}
                                        audio={casetteItem.audio}
                                        voiceTime={casetteItem.voiceTime}
                                        mediaLinks={casetteItem.mediaLinks}
                                        chapters={casetteItem.chapters}
                                        cassetteData={talent.cassetteData}
                                        metaData={casetteItem.metaData}
                                    />}
                                />
                            )
                        })
                    );
                })}
                <Route path="/privacypolicy" element={
                    <Privacypolicy />
                } />
                <Route path="/terms" element={
                    <Terms />
                } />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default App;